import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage, loadUser } from "../actions/auth";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import ButtonBase from "@mui/material/ButtonBase";
import DeleteIcon from "@mui/icons-material/Delete";
import { themeStyle, themeStyleDark } from "../components/Components";
import Calendar from "react-calendar";

function Activity() {
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const colorList = [
    "#e5f5e0",
    state.auth.theme == "dark"
      ? themeStyleDark.secondaryButtonColor
      : themeStyle.secondaryButtonColor,
    "#fee6ce",
  ];
  const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
  const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [sortByDate, setSortByDate] = useState(false);

  const [userIndex, setUserIndex] = useState(0);
  const [activity, setActivity] = useState([]);
  const [ogActivity, setOGActivity] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [regularId, setRegularId] = useState("");
  const [currentRegulars, setCurrentRegulars] = useState([]);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [id, setId] = useState("");
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [leaveSubject, setLeaveSubject] = useState("");
  const [selectedPage, setSelectedPage] = useState("ATTENDANCE");

  const [leaveDate, setLeaveDate] = useState("");
  const [email, setEmail] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    var tempActivity = Array.from(ogActivity);
    var dayActivity = [];
    for (var i = 0; i < tempActivity.length; i++) {
      if (
        moment(tempActivity[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
          moment(date),
          "date"
        )
      ) {
        dayActivity.push(tempActivity[i]);
      }
      setActivity(dayActivity);
    }
  };

  const handlePageSelect = (event) => {
    if (state.auth.user.is_staff) {
      setUserIndex(0);

      setOGActivity(users[0]["activity"]);
      setCurrentRegulars(users[0]["regular_requests"]);
      var tempPresent = [];
      var tempRegular = [];

      for (var i = users[0]["activity"].length - 1; i >= 0; i--) {
        if (users[0]["activity"][i]["type"] == "LOGIN") {
          tempPresent.push(
            moment(
              users[0]["activity"][i]["datetime"],
              "DD-MM-YYYY, hh:mm:ss"
            ).format("DD-MM-YYYY")
          );
        }
        if (users[0]["activity"][i]["type"] == "REGULARIZED") {
          tempRegular.push(
            moment(
              users[0]["activity"][i]["datetime"],
              "DD-MM-YYYY, hh:mm:ss"
            ).format("DD-MM-YYYY")
          );
        }
      }

      var tempActivity = users[0]["activity"];
      var dayActivity = [];
      for (var i = 0; i < tempActivity.length; i++) {
        if (
          moment(tempActivity[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
            moment(selectedDate),
            "date"
          )
        ) {
          dayActivity.push(tempActivity[i]);
        }
      }

      var tempActi = JSON.parse(state.auth.user.activity);
      var date = new Date();
      var dayActi = [];
      for (var i = 0; i < tempActi.length; i++) {
        if (
          moment(tempActi[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
            moment(date),
            "date"
          )
        ) {
          dayActi.push(tempActi[i]);
        }
      }
      if (dayActi.length != 0) {
        setDisable(true);
      }

      setActivity(dayActivity);
      setPresentDateList(tempPresent);
      setRegularList(tempPresent);
    }
    setSelectedPage(event.currentTarget.value);
  };

  const handleClose = () => {
    setOpen5(false);
    setOpen6(false);
  };

  function getUsersList() {
    var userss = Object.keys(users).map((user, index) => (
      <MenuItem value={index}>{users[index]["full_name"]}</MenuItem>
    ));
    return userss;
  }

  const [eventsList, setEventsList] = useState([]);
  const [eventsDateList, setEventsDateList] = useState([]);

  const [importantDatesList, setimportantDatesList] = useState([]);
  const [importantDatesDateList, setimportantDatesDateList] = useState([]);

  const [holidayList, setHolidayList] = useState([]);
  const [holidayDateList, setHolidayDateList] = useState([]);

  const [regularList, setRegularList] = useState([]);

  const [presentDateList, setPresentDateList] = useState([]);

  const [disable, setDisable] = useState(false);

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data);
      setOGActivity(
        res.data[0]["activity"] == null ? [] : res.data[0]["activity"]
      );

      var tempPresent = [];
      var tempRegular = [];

      for (var i = res.data[0]["activity"].length - 1; i >= 0; i--) {
        if (res.data[0]["activity"][i]["type"] == "LOGIN") {
          tempPresent.push(
            moment(
              res.data[0]["activity"][i]["datetime"],
              "DD-MM-YYYY, hh:mm:ss"
            ).format("DD-MM-YYYY")
          );
        }
        if (res.data[0]["activity"][i]["type"] == "REGULARIZED") {
          tempRegular.push(
            moment(
              res.data[0]["activity"][i]["datetime"],
              "DD-MM-YYYY, hh:mm:ss"
            ).format("DD-MM-YYYY")
          );
        }
      }

      setPresentDateList(tempPresent);
      setRegularList(tempRegular);

      var dayActivity = [];
      var tempActivity = res.data[0]["activity"];

      for (var i = 0; i < tempActivity.length; i++) {
        if (
          moment(tempActivity[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
            moment(selectedDate),
            "date"
          )
        ) {
          dayActivity.push(tempActivity[i]);
        }
      }

      setActivity(dayActivity);

      setCurrentRegulars(res.data[userIndex]["regular_requests"]);

      var tempActi = JSON.parse(state.auth.user.activity);
      var date = new Date();
      var dayActi = [];
      for (var i = 0; i < tempActi.length; i++) {
        if (
          moment(tempActi[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
            moment(date),
            "date"
          )
        ) {
          dayActi.push(tempActi[i]);
        }
      }
      if (dayActi.length != 0) {
        setDisable(true);
      }

      axios.post(API_URL + "get_events_list", body, config).then((res) => {
        setHolidayList(res.data["holidays"]);
        setimportantDatesList(res.data["important_dates"]);
        var tempEventDateList = [];
        var tempHolidayDateList = [];
        var tempImportantDateList = [];
        var tempEventsList = res.data["events"];
        for (var i = res.data["events"].length - 1; i >= 0; i--) {
          if (
            moment(res.data["events"][i]["date"], "DD-MM-YYYY").isBefore(
              moment()
            )
          ) {
            tempEventsList.splice(i, 1);
          }
        }

        setEventsList(tempEventsList);
        for (var i = 0; i < res.data["holidays"].length; i++) {
          tempHolidayDateList.push(res.data["holidays"][i]["date"]);
        }

        for (var i = 0; i < res.data["important_dates"].length; i++) {
          tempImportantDateList.push(res.data["important_dates"][i]["date"]);
        }

        setEventsDateList(tempEventDateList);
        setimportantDatesDateList(tempImportantDateList);
        setHolidayDateList(tempHolidayDateList);

        setIsLoading(false);
      });
    });
  }

  function approveRegular(approval) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["regular_request_id"] = regularId;
    body["regular_date"] = moment(leaveDate, "DD-MM-YYYY").format("DD-MM-YYYY");
    body["email"] = users[userIndex]["email"];
    body["admin"] = state.auth.user.email;
    body["approval"] = approval ? "APPROVED" : "REJECTED";

    body = JSON.stringify(body);

    axios
      .post(API_URL + "approve_regular_request", body, config)
      .then((res) => {
        getData();
        handleClose();
        createAlert(
          "SUCCESS",
          "Success.",
          approval ? "Regularization APPROVED" : "Regularization REJECTED"
        );
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function handleUserChange(e) {
    setUserIndex(e.target.value);
    setOGActivity(users[e.target.value]["activity"]);
    setCurrentRegulars(users[e.target.value]["regular_requests"]);
    var tempPresent = [];
    var tempRegular = [];

    for (var i = users[e.target.value]["activity"].length - 1; i >= 0; i--) {
      if (users[e.target.value]["activity"][i]["type"] == "LOGIN") {
        tempPresent.push(
          moment(
            users[e.target.value]["activity"][i]["datetime"],
            "DD-MM-YYYY, hh:mm:ss"
          ).format("DD-MM-YYYY")
        );
      }
      if (users[e.target.value]["activity"][i]["type"] == "REGULARIZED") {
        console.log(users[e.target.value]["activity"][i]);

        tempRegular.push(
          moment(
            users[e.target.value]["activity"][i]["datetime"],
            "DD-MM-YYYY, hh:mm:ss"
          ).format("DD-MM-YYYY")
        );
      }
    }

    var tempActivity = users[e.target.value]["activity"];
    var dayActivity = [];
    for (var i = 0; i < tempActivity.length; i++) {
      if (
        moment(tempActivity[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
          moment(selectedDate),
          "date"
        )
      ) {
        dayActivity.push(tempActivity[i]);
      }
    }
    if (dayActivity.length != 0) {
      setDisable(true);
    }
    setActivity(dayActivity);
    setPresentDateList(tempPresent);
    setRegularList(tempRegular);
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (
        state.auth.user.is_staff ||
        state.auth.user.is_manager ||
        state.auth.user.is_hr
      ) {
        getData();
      } else {
        setOGActivity(JSON.parse(state.auth.user.activity));
        var tempActivity = JSON.parse(state.auth.user.activity);
        setCurrentRegulars(JSON.parse(state.auth.user.regular_requests));
        var date = new Date();
        var dayActivity = [];
        for (var i = 0; i < tempActivity.length; i++) {
          if (
            moment(tempActivity[i]["datetime"], "DD-MM-YYYY, hh:mm:ss").isSame(
              moment(date),
              "date"
            )
          ) {
            dayActivity.push(tempActivity[i]);
          }
        }
        if (dayActivity.length != 0) {
          setDisable(true);
        }
        setActivity(dayActivity);

        var tempPresent = [];
        var tempRegular = [];

        for (var i = tempActivity.length - 1; i >= 0; i--) {
          if (tempActivity[i]["type"] == "LOGIN") {
            tempPresent.push(
              moment(
                tempActivity[i]["datetime"],
                "DD-MM-YYYY, hh:mm:ss"
              ).format("DD-MM-YYYY")
            );
          }
          if (tempActivity[i]["type"] == "REGULARIZED") {
            tempRegular.push(
              moment(
                tempActivity[i]["datetime"],
                "DD-MM-YYYY, hh:mm:ss"
              ).format("DD-MM-YYYY")
            );
          }
        }
        var body = {};
        const config = {
          headers: {
            Authorization: state.auth.token,
            "Content-Type": "application/json",
          },
        };

        body["email"] = state.auth.user.email;

        setPresentDateList(tempPresent);
        setRegularList(tempRegular);

        axios.post(API_URL + "get_events_list", body, config).then((res) => {
          setHolidayList(res.data["holidays"]);
          setimportantDatesList(res.data["important_dates"]);
          var tempEventDateList = [];
          var tempHolidayDateList = [];
          var tempImportantDateList = [];
          var tempEventsList = res.data["events"];
          for (var i = res.data["events"].length - 1; i >= 0; i--) {
            if (
              moment(res.data["events"][i]["date"], "DD-MM-YYYY").isBefore(
                moment()
              )
            ) {
              tempEventsList.splice(i, 1);
            }
          }

          setEventsList(tempEventsList);
          for (var i = 0; i < res.data["holidays"].length; i++) {
            tempHolidayDateList.push(res.data["holidays"][i]["date"]);
          }

          for (var i = 0; i < res.data["important_dates"].length; i++) {
            tempImportantDateList.push(res.data["important_dates"][i]["date"]);
          }

          setEventsDateList(tempEventDateList);
          setimportantDatesDateList(tempImportantDateList);
          setHolidayDateList(tempHolidayDateList);

          setIsLoading(false);
        });

        setIsLoading(false);
      }
    }
  }, []);

  if (state.auth.refresh) {
    getData();
    dispatch(loadUser());
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function submitRegularize() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["leave_subject"] = leaveSubject;
    body["date"] = moment(selectedDate).format("DD-MM-YYYY");
    body["upload_date"] = moment().format("DD-MM-YYYY");
    axios
      .post(API_URL + "add_regular_request", body, config)
      .then(() => {
        setIsSubmitting(false);
        setOpen4(false);
        setLeaveSubject("");
        createAlert("SUCCESS", "Success.", "Request submitted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function deleteRegular() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    body["email"] = state.auth.user.email;
    body["regular_id"] = id;

    axios
      .post(API_URL + "delete_regular_request", body, config)
      .then(() => {
        setIsSubmitting(false);
        createAlert("SUCCESS", "Success.", "Regularization request deleted.");
        setOpen6(false);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function markAttendance() {
    setIsLoading(true);
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    axios.post(API_URL + "mark_attendance", body, config).then((res) => {
      createAlert(
        "SUCCESS",
        "Success.",
        "Your attendance has been marked for today."
      );
      setIsLoading(false);
      setOpen2(false);
    });
  }

  function getPageContent() {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      if (selectedPage == "REGULARIZE") {
        return (
          <Fragment>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Grid container spacing={3}>
                  {currentRegulars.length == 0 ? (
                    <div
                      style={{
                        marginTop: "200px",
                        height: "100px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Fab
                          size="large"
                          style={{
                            border: "3px solid #aaa",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          aria-label="add"
                        >
                          <PriorityHighIcon
                            style={{
                              color: "grey",
                            }}
                          />
                        </Fab>
                        <br />
                        <h4 style={{ color: "grey" }}>
                          No regularization requests for this user.
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {currentRegulars.map((regular, index) => (
                    <Grid item md={3}>
                      <Box
                        style={{
                          minHeight: "100%",
                          borderRadius: "6px",
                          border: "1.3px solid #d7ecff",
                          backgroundColor: themeStyle.primaryCardColor,
                          boxShadow: "none",
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            margin: "-5px 0px 0px 0px",
                            fontSize: "18px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonTextColor
                                : themeStyle.secondaryButtonTextColor,
                          }}
                        >
                          {regular["name"]}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          {regular["subject"]}
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            color:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryColor
                                : themeStyle.primaryColor,
                          }}
                        >
                          {regular["class"]}
                        </div>
                        <div style={{ marginTop: "-5px" }}>
                          {moment(regular["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <div style={{ marginTop: "-5px", color: "grey" }}>
                          {moment(regular["upload_date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <div style={{ margin: "0px 0px 0px 0px" }}>
                          {regular["description"]}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "-5px",
                            marginRight: "-5px",
                          }}
                        >
                          {console.log(regular)}
                          <Fab
                            size="small"
                            style={{
                              transform: "scale(0.8)",
                              boxShadow: "none",
                              backgroundColor:
                                regular["status"] == "PENDING"
                                  ? state.auth.theme == "dark"
                                    ? themeStyleDark.primaryColor
                                    : themeStyle.primaryColor
                                  : regular["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                            onClick={() => {
                              if (regular["status"] == "PENDING") {
                                setRegularId(regular["id"]);
                                setEmail(regular["email"]);
                                setLeaveDate(regular["date"]);
                                setOpen5(true);
                              }
                            }}
                            aria-label="add"
                          >
                            {regular["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            ) : (
                              <Fragment>
                                {regular["status"] == "APPROVED" ? (
                                  <CheckIcon
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                ) : (
                                  <CloseIcon
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                )}{" "}
                              </Fragment>
                            )}
                          </Fab>
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item md={4}>
                <span
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontSize: "14px",
                  }}
                >
                  Select User
                </span>
                <Select
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  variant={"outlined"}
                  fullWidth
                  value={userIndex}
                  onChange={(e) => handleUserChange(e)}
                >
                  {getUsersList()}
                </Select>

                <h3>OR you can</h3>

                <div
                  style={{
                    marginTop: "25px",
                    marginBottom: "-15px",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  <h3>Apply for Regularization</h3>
                </div>
                {moment().date() <= 25 ? (
                  <div>
                    <span
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontSize: "16px",
                      }}
                    >
                      Choose a date
                    </span>
                    <br></br>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{
                          height: "40px",
                          width: "100%",
                          marginTop: "5px",
                          marginBottom: "20px",
                        }}
                        inputFormat="dd-MM-yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              marginTop: "2px",
                              marginBottom: "5px",
                              height: "40px",
                            }}
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <br></br>
                    <div
                      style={{
                        margin: "15px 0px 5px 0px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontSize: "16px",
                      }}
                    >
                      Reason
                    </div>
                    <TextField
                      value={leaveSubject}
                      style={{ marginTop: "2px" }}
                      onChange={(e) => setLeaveSubject(e.target.value)}
                      fullWidth
                      placeholder="Reason"
                      variant="outlined"
                      size="small"
                    />
                    <br></br>
                    <br></br>

                    <Button
                      onClick={() => {
                        setOpen4(true);
                      }}
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        height: "35px",
                        marginTop: "-1px",
                        backgroundColor: themeStyle.primaryColor,
                      }}
                      variant="contained"
                      component="span"
                    >
                      <p>Submit Regularization</p>
                    </Button>
                    {isSubmitting ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  "You can apply for regularization only until 25th of every month. This form will be available on the 1st of next month."
                )}
              </Grid>
            </Grid>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <h3>Calendar</h3>
                    <div style={{ marginTop: "-35px", marginBottom: "15px" }}>
                      <Box
                        borderRadius="16px"
                        style={{
                          marginTop: "40px",
                        }}
                      >
                        <div className="calendar-container">
                          <Calendar
                            style={{
                              width: "100%",
                              backgroundColor: "black",
                            }}
                            onChange={handleDateChange}
                            value={selectedDate}
                            tileClassName={({ date, view }) => {
                              if (
                                eventsDateList.find(
                                  (x) => x === moment(date).format("DD-MM-YYYY")
                                )
                              ) {
                                return "highlightEvent";
                              } else if (
                                holidayDateList.find(
                                  (x) => x === moment(date).format("DD-MM-YYYY")
                                )
                              ) {
                                return "highlightHoliday";
                              } else if (
                                importantDatesDateList.find(
                                  (x) => x === moment(date).format("DD-MM-YYYY")
                                )
                              ) {
                                return "highlightImportant";
                              } else if (
                                presentDateList.find(
                                  (x) => x === moment(date).format("DD-MM-YYYY")
                                )
                              ) {
                                return "highlightPresent";
                              } else if (
                                regularList.find(
                                  (x) => x === moment(date).format("DD-MM-YYYY")
                                )
                              ) {
                                return "highlightRegular";
                              } else if (date.getDay() == 6) {
                                return "highlightSaturday";
                              } else if (date.getDay() == 0) {
                                return "highlightSaturday";
                              } else {
                                if (moment(date).isBefore(Date.now()))
                                  return "highlightAbsent";
                              }
                            }}
                          />
                        </div>
                      </Box>
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          padding: 12,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            marginRight: "4px",
                            color: "white",
                            fontSize: "13px",
                            backgroundColor: "#6a6fff",
                            padding: "5px",
                            borderRadius: "20px",
                          }}
                        >
                          {" "}
                          HOLIDAY
                        </span>
                        <span
                          style={{
                            marginRight: "4px",
                            color: "white",
                            fontSize: "13px",
                            backgroundColor: "green",
                            padding: "5px",
                            borderRadius: "20px",
                          }}
                        >
                          {" "}
                          PRESENT
                        </span>

                        <span
                          style={{
                            marginRight: "4px",
                            color: "white",
                            fontSize: "13px",
                            backgroundColor: "red",
                            padding: "5px",
                            borderRadius: "20px",
                          }}
                        >
                          {" "}
                          ABSENT
                        </span>
                        <span
                          style={{
                            marginRight: "4px",
                            color: "white",
                            fontSize: "13px",
                            backgroundColor: "blue",
                            padding: "5px",
                            borderRadius: "20px",
                          }}
                        >
                          {" "}
                          REGULARIZED
                        </span>
                        <span
                          style={{
                            marginRight: "4px",
                            color: "white",
                            fontSize: "13px",
                            backgroundColor: "#fa9fb5",
                            padding: "5px",
                            borderRadius: "20px",
                          }}
                        >
                          {" "}
                          EVENT
                        </span>

                        <span
                          style={{
                            marginRight: "4px",
                            color: "white",
                            fontSize: "13px",
                            backgroundColor: "#fdbb84",
                            padding: "5px",
                            borderRadius: "20px",
                          }}
                        >
                          {" "}
                          IMPORTANT
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    {activity.length == 0 ? (
                      <div
                        style={{
                          marginTop: "200px",
                          height: "100px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <Fab
                            size="large"
                            style={{
                              border: "3px solid #aaa",
                              boxShadow: "none",
                              backgroundColor: "transparent",
                            }}
                            aria-label="add"
                          >
                            <PriorityHighIcon
                              style={{
                                color: "grey",
                              }}
                            />
                          </Fab>
                          <br />
                          <h4 style={{ color: "grey" }}>
                            No actvity recorded for this user on this day
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.primaryCardColor
                                : themeStyle.primaryCardColor,
                            borderRadius: "15px",
                            padding: "15px",
                            boxShadow: "0px 0px 30px #ccc",
                          }}
                        >
                          <Grid
                            container
                            spacing={2}
                            style={{
                              justifyContent: "flex-start",
                              textAlign: "left",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                              paddingBottom: "20px",
                              borderBottom:
                                state.auth.theme == "dark"
                                  ? "1px solid" + themeStyleDark.primaryColor
                                  : "1px solid" + themeStyle.primaryColor,
                            }}
                          >
                            <Grid item md={2}>
                              No.
                            </Grid>
                            <Grid item md={5} style={{ textAlign: "center" }}>
                              Activity
                            </Grid>
                            <Grid item md={5} style={{ textAlign: "center" }}>
                              Time
                            </Grid>
                          </Grid>

                          {activity.map((acti, index) => (
                            <Grid
                              container
                              spacing={2}
                              style={{
                                color: "black",
                                padding: "15px",
                              }}
                            >
                              <Grid item md={2}>
                                {index + 1}
                              </Grid>

                              <Grid item md={5} style={{ textAlign: "center" }}>
                                {acti["type"]}
                              </Grid>
                              <Grid style={{ textAlign: "center" }} item md={5}>
                                {acti["datetime"]}
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <h2>View user activity</h2>
                <span
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontSize: "14px",
                  }}
                >
                  Select User
                </span>
                <Select
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  variant={"outlined"}
                  fullWidth
                  value={userIndex}
                  onChange={(e) => {
                    handleUserChange(e);
                  }}
                >
                  {getUsersList()}
                </Select>
                <span
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontSize: "16px",
                  }}
                >
                  Choose a date
                </span>

                <br></br>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{
                      height: "40px",
                      width: "100%",
                      marginTop: "5px",
                      marginBottom: "20px",
                    }}
                    inputFormat="dd-MM-yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        style={{
                          width: "100%",
                          marginTop: "2px",
                          marginBottom: "5px",
                          height: "40px",
                        }}
                        size="small"
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                <br></br>
                <br></br>

                <br />
                <h2>Or you can</h2>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  <Button
                    disabled={disable}
                    onClick={() => {
                      setOpen2(true);
                    }}
                    style={{
                      width: "100%",
                      fontSize: "15px",
                      height: "35px",
                      marginTop: "-1px",
                      backgroundColor: disable
                        ? "#ddd"
                        : themeStyle.primaryColor,
                    }}
                    variant="contained"
                    component="span"
                  >
                    <p>LOGIN for TODAY</p>
                  </Button>
                  <Link
                    style={{
                      marginLeft: "20px",
                      width: "100%",
                      textDecoration: "none",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                    }}
                    to={"/logout"}
                  >
                    <Button
                      onClick={() => {
                        setOpen2(true);
                      }}
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        height: "35px",
                        marginTop: "-1px",
                        backgroundColor: themeStyle.primaryColor,
                      }}
                      variant="contained"
                      component="span"
                    >
                      <p>LOGOUT FOR TODAY</p>
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Fragment>
        );
      }
    } else {
      if (selectedPage == "REGULARIZE") {
        return (
          <div style={{ width: "100%" }}>
            <Grid container spacing={5}>
              <Grid item md={8}>
                {JSON.parse(state.auth.user.regular_requests).length == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        No Regularization requests.
                      </h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {currentRegulars.map((regular, index) => (
                  <Box
                    p={2}
                    style={{
                      height: "80px",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      border:
                        regular["status"] == "PENDING"
                          ? "1.5px solid" + state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor
                          : "none",
                      backgroundColor:
                        regular["status"] == "PENDING"
                          ? "white"
                          : regular["status"] == "APPROVED"
                          ? colorList[0]
                          : colorList[2],
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItemsL: "center",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <div
                          style={{
                            margin: "-25px 0px 0px -3px",
                          }}
                        >
                          <h3
                            style={{
                              lineHeight: "20px",
                              color:
                                regular["status"] == "PENDING"
                                  ? colorList2[1]
                                  : regular["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            {regular["subject"]}
                          </h3>
                        </div>
                        <div
                          style={{
                            margin: "-15px 0px 0px -3px",
                            fontSize: "14px",
                          }}
                        >
                          {moment(regular["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {regular["status"] == "PENDING" ? (
                            <ButtonBase
                              style={{
                                cursor: "pointer",
                                marginLeft: "-10px",
                                display: "flex",
                                backgroundColor: "#ff3333",
                                borderRadius: "6px",
                                padding: "3px",
                                alignItems: "center",
                                justifyContent: "space-between",
                                position: "relative",
                                bottom: "62px",
                                height: "25px",
                                width: "25px",
                                right: "-105px",
                              }}
                              onClick={() => {
                                setId(regular["id"]);
                                setOpen6(true);
                              }}
                            >
                              <DeleteIcon
                                style={{ color: "white", fontSize: "18px" }}
                              />
                            </ButtonBase>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          marginTop:
                            regular["status"] == "PENDING" ? "-12px" : "-5px",
                          marginRight: "-15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            textAlign="center"
                            p={1}
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "60px",
                              backgroundColor:
                                regular["status"] == "PENDING"
                                  ? colorList2[1]
                                  : regular["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                              color: "white",
                            }}
                          >
                            {regular["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : regular["status"] == "APPROVED" ? (
                              <CheckIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : (
                              <CloseIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            )}
                          </Box>
                          <b
                            style={{
                              marginTop: "5px",
                              color:
                                regular["status"] == "PENDING"
                                  ? colorList2[1]
                                  : regular["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "lighter",
                              }}
                            >
                              {regular["status"]}
                            </span>
                          </b>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
              </Grid>
              <Grid item md={4}>
                <div
                  style={{
                    marginTop: "-25px",
                    marginBottom: "-15px",
                    fontWeight: "bold",
                    fontSize: "24px",
                  }}
                >
                  <h3>Apply for Regularization</h3>
                </div>
                {moment().date() <= 25 ? (
                  <div>
                    <span
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontSize: "16px",
                      }}
                    >
                      Choose a date
                    </span>
                    <br></br>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        sx={{
                          height: "40px",
                          width: "100%",
                          marginTop: "5px",
                          marginBottom: "20px",
                        }}
                        inputFormat="dd-MM-yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              marginTop: "2px",
                              marginBottom: "5px",
                              height: "40px",
                            }}
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <br></br>
                    <div
                      style={{
                        margin: "15px 0px 5px 0px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        fontSize: "16px",
                      }}
                    >
                      Reason
                    </div>
                    <TextField
                      value={leaveSubject}
                      style={{ marginTop: "2px" }}
                      onChange={(e) => setLeaveSubject(e.target.value)}
                      fullWidth
                      placeholder="Reason"
                      variant="outlined"
                      size="small"
                    />
                    <br></br>
                    <br></br>

                    <Button
                      onClick={() => {
                        setOpen4(true);
                      }}
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        height: "35px",
                        marginTop: "-1px",
                        backgroundColor: themeStyle.primaryColor,
                      }}
                      variant="contained"
                      component="span"
                    >
                      <p>Submit Regularization</p>
                    </Button>
                    {isSubmitting ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  "You can apply for regularization only until 25th of every month. This form will be available on the 1st of next month."
                )}
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return (
          <Grid container spacing={3}>
            <Grid item md={6}>
              <h3>Calendar</h3>
              <div style={{ marginTop: "-35px", marginBottom: "15px" }}>
                <Box
                  borderRadius="16px"
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <div className="calendar-container">
                    <Calendar
                      style={{
                        width: "100%",
                        backgroundColor: "black",
                      }}
                      onChange={handleDateChange}
                      value={selectedDate}
                      tileClassName={({ date, view }) => {
                        if (
                          eventsDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightEvent";
                        } else if (
                          holidayDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightHoliday";
                        } else if (
                          importantDatesDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightImportant";
                        } else if (
                          presentDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightPresent";
                        } else if (
                          regularList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightRegular";
                        } else if (date.getDay() == 6) {
                          return "highlightSaturday";
                        } else if (date.getDay() == 0) {
                          return "highlightSaturday";
                        } else {
                          if (
                            moment(date).isBefore(moment().subtract(1, "days"))
                          )
                            return "highlightAbsent";
                        }
                      }}
                    />
                  </div>
                </Box>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    padding: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#6a6fff",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    HOLIDAY
                  </span>
                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "green",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    PRESENT
                  </span>

                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "red",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    ABSENT
                  </span>
                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "blue",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    REGULARIZED
                  </span>
                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#fa9fb5",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    EVENT
                  </span>

                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#fdbb84",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    IMPORTANT
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item md={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "15px",
                }}
              >
                <Button
                  disabled={disable}
                  onClick={() => {
                    setOpen2(true);
                  }}
                  style={{
                    width: "100%",
                    fontSize: "15px",
                    height: "35px",
                    marginTop: "-1px",
                    backgroundColor: disable ? "#ddd" : themeStyle.primaryColor,
                  }}
                  variant="contained"
                  component="span"
                >
                  <p>LOGIN FOR TODAY</p>
                </Button>
                <Link
                  style={{
                    marginLeft: "20px",
                    width: "100%",
                    textDecoration: "none",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                  }}
                  to={"/logout"}
                >
                  <Button
                    onClick={() => {
                      setOpen2(true);
                    }}
                    style={{
                      width: "100%",
                      fontSize: "15px",
                      height: "35px",
                      marginTop: "-1px",
                      backgroundColor: themeStyle.primaryColor,
                    }}
                    variant="contained"
                    component="span"
                  >
                    <p>LOGOUT FOR TODAY</p>
                  </Button>
                </Link>
              </div>
              {activity.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No actvity recorded on this day
                    </h4>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryCardColor
                          : themeStyle.primaryCardColor,
                      borderRadius: "15px",
                      padding: "15px",
                      boxShadow: "0px 0px 30px #ccc",
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{
                        justifyContent: "flex-start",
                        textAlign: "left",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        paddingBottom: "20px",
                        borderBottom:
                          state.auth.theme == "dark"
                            ? "1px solid" + themeStyleDark.primaryColor
                            : "1px solid" + themeStyle.primaryColor,
                      }}
                    >
                      <Grid item md={2}>
                        No.
                      </Grid>
                      <Grid item md={5} style={{ textAlign: "center" }}>
                        Activity
                      </Grid>
                      <Grid item md={5} style={{ textAlign: "center" }}>
                        Time
                      </Grid>
                    </Grid>

                    {activity.map((acti, index) => (
                      <Grid
                        container
                        spacing={2}
                        style={{
                          color: "black",
                          padding: "15px",
                        }}
                      >
                        <Grid item md={2}>
                          {index + 1}
                        </Grid>

                        <Grid item md={5} style={{ textAlign: "center" }}>
                          {acti["type"]}
                        </Grid>
                        <Grid style={{ textAlign: "center" }} item md={5}>
                          {acti["datetime"]}
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        );
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.isMfa) {
        if (isLoading) {
          return (
            <Fragment>
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: themeStyle.backgroundColor,
                }}
              >
                <CircularProgress style={{ color: themeStyle.primaryColor }} />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1} justifyContent={"flex-end"}>
                    <Grid item md={4}>
                      {selectedPage == "ATTENDANCE" ? (
                        <Button
                          fullWidth
                          value="ATTENDANCE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: themeStyle.primaryColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            ATTENDANCE
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="ATTENDANCE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            height: "30px",
                            marginTop: "-15px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonColor
                                : themeStyle.secondaryButtonColor,
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonTextColor
                                  : themeStyle.secondaryButtonTextColor,
                            }}
                          >
                            ATTENDANCE
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "REGULARIZE" ? (
                        <Button
                          fullWidth
                          value="REGULARIZE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: themeStyle.primaryColor,
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            REGULARIZE
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="REGULARIZE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            height: "30px",
                            marginTop: "-15px",
                            backgroundColor:
                              state.auth.theme == "dark"
                                ? themeStyleDark.secondaryButtonColor
                                : themeStyle.secondaryButtonColor,
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.secondaryButtonTextColor
                                  : themeStyle.secondaryButtonTextColor,
                            }}
                          >
                            REGULARIZE
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Mark Login for today?</h3>
                      <p>This action is irreversible</p>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen2(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => markAttendance()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open6}
                onClose={() => setOpen6(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open6}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen6(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Delete this reqgularization request?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen6(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => deleteRegular()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open5}
                onClose={() => setOpen5(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open5}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen5(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Approve this regularization request?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => approveRegular(true)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => approveRegular(false)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open4}
                onClose={() => setOpen4(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open4}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: themeStyle.backgroundColor,
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen4(false)}
                            style={{
                              color:
                                state.auth.theme == "dark"
                                  ? themeStyleDark.primaryColor
                                  : themeStyle.primaryColor,
                            }}
                          />
                        </IconButton>
                      </div>
                      <h3>Submit Regularization request?</h3>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => setOpen4(false)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          NO
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => submitRegularize()}
                        >
                          YES
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  padding: "100px 50px 90px 115px",
                  backgroundColor: themeStyle.backgroundColor,
                  height: "100vh",
                  width: "100vw",
                }}
              >
                {getPageContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      dispatch({
        type: "AUTH_ERROR",
      });
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default Activity;
